#cookiescript_injected {
	background-color: #e6e6e6;
	z-index: 999997;
	opacity: 1;
	font-size: 14px;
	font-weight: normal;
	font-family: 'Open Sans', Arial, 'Trebuchet MS', 'Segoe UI', 'Helvetica', sans-serif;
	box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.35);
	color: #181813;
	box-sizing: border-box;
}

.cookiescript_checkbox_label {
	cursor: pointer;
	display: flex;
	align-items: center;
	padding: 0 4px;
	line-height: 1.5;
	margin: 0;
}

#cookiescript_close {
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 29px;
	line-height: 13px;
	cursor: pointer;
	color: #181813;
	height: 15px;
	width: 15px;
	margin: 0;
	padding: 0;
	overflow: hidden;
	letter-spacing: 0;
	font-family: 'Trebuchet MS', 'Arial', sans-serif;
	font-weight: 100;
	opacity: 0.85;
	z-index: 999999;
}

#cookiescript_buttons {
	display: flex;
	flex-direction: row;
	font-weight: 700;
}

#cookiescript_manage_wrap {
	text-transform: uppercase;
	text-align: center;
	font-size: 11px;
	letter-spacing: 0.1px;
	font-weight: 500;
	outline: none;
}

#cookiescript_manage {
	display: inline;
	cursor: pointer;
	color: #181813;
	opacity: 0.85;
}

#cookiescript_manage #cookiescript_manageicon .cookiescript_gear {
	fill: #181813;
}

#cookiescript_manage:hover #cookiescript_manageicon .cookiescript_gear {
	fill: #009;;
}

svg#cookiescript_manageicon {
	width: 15px;
	height: 15px;
	display: inline;
	margin: 0 5px 0 0;
	padding: 0;
	position: relative;
	top: 3px;
	vertical-align: baseline;
}

#cookiescript_header {
	background-color: transparent;
	z-index: 999998;
	color: #181813;
	font-size: 17px;
	line-height: 1.3;
	font-weight: 600;
	letter-spacing: 0.4px;
	opacity: 1;
}

.cookiescript_checkbox {
	display: flex;
	flex-direction: row;
}

input.cookiescript_checkbox_input {
}

span.cookiescript_checkbox_text {
	display: inline-block;
	font-size: 11px;
	margin: 0;
	text-transform: uppercase;
	font-weight: 500;
	color: #181813;
}

#cookiescript_accept,
#cookiescript_save {
	border: 0;
	transition: all 0.25s ease 0s;
	background-color: #009;
	color: white;
	text-transform: uppercase;
	font-size: 11px;
	text-align: center;
	line-height: 3.3;
	letter-spacing: 0.4px;
}

/*IE 9 fixes*/
@media screen and (min-width: 0\0
) and (min-resolution: .001dpcm) {
	.cookiescript_checkbox_label {
		position: relative;
		top: -10px;
	}
	
	#cookiescript_accept, #cookiescript_reject, #cookiescript_save {
		display: inline-block;
	}
	
	#cookiescript_buttons {
		text-align: center;
	}
}

#cookiescript_reject {
	border: 1px solid #009;
	text-align: center;
	line-height: 3;
	font-size: 11px;
	text-transform: uppercase;
	letter-spacing: 0.4px;
	color: #009;
	background: white;
}


#cookiescript_accept, #cookiescript_reject, #cookiescript_save {
	font-weight: 500;
	cursor: pointer;
	white-space: nowrap;
	transition-duration: 100ms;
	transition-timing-function: ease-in-out;
	min-width: 103px;
}

.cookiescript_bigger {
	transform: scale(1.1);
}

#cookiescript_link {
	text-decoration: none;
	color: #181813;
	font-size: 9px;
	text-align: center;
	font-weight: 400;
	text-transform: uppercase;
	opacity: 0.8;
}

#cookiescript_readmore,
#cookiescript_reportlink,
#cookiescript_cookiescriptlink {
	border: 0;
	padding: 0;
	cursor: pointer;
	margin: 0;
	transition: all 100ms ease 0s;
	background-color: transparent;
	color: #009;
	display: inline;
	font-size: 11px;
}

#cookiescript_description {
	color: #181813;
	font-size: 11px;
	letter-spacing: 0.3px;
	line-height: 1.8;
	font-weight: 400;
	opacity: 0.85;
}

#cookiescript_checkboxs {
}

#cookiescript_close:hover,
#cookiescript_manage:hover,
#cookiescript_link:hover {
	opacity: 1;
}

#cookiescript_badge {
	position: fixed;
	line-height: 0;
	cursor: pointer;
	z-index: 99999;
	font-size: 0;
	color: #999;
	left: 10px;
	display: flex;
	flex-direction: row;
	opacity: 1;
}

#cookiescript_badgetext {
	text-transform: uppercase;
	font-weight: 600;
	font-family: 'Open Sans', Arial, 'Trebuchet MS', 'Segoe UI', 'Helvetica', sans-serif;
	overflow: hidden;
	transition-duration: 500ms;
	white-space: nowrap;
	padding-right: 0;
	color: #181813;
}

#cookiescript_badgesvg {
	width: 40px;
	height: 40px;
}


#cookiescript_badge {
	top: 0;
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
	padding-bottom: 10px;
	padding-top: 8px;
	padding-left: 12px;
	padding-right: 12px;
	transition-duration: 200ms;
	box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.2);
	background: #e6e6e6;
}

#cookiescript_badgetext {
	font-size: 11px;
	line-height: 1;
}

#cookiescript_badge:hover {
	padding-top: 13px;
}

@media only screen and (max-width: 414px) {
	#cookiescript_badge {
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}
}

@media print {
	#cookiescript_injected {
		display: none;
	}
}

.cookiescript_fullreport,
.cookiescript_fullreport tbody,
.cookiescript_fullreport thead,
.cookiescript_fullreport tr,
.cookiescript_fullreport th,
.cookiescript_fullreport td {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

table.cookiescript_fullreport {
	border-collapse: collapse;
	border-spacing: 0;
}


#cookiescript_maintabs {
	display: flex;
	justify-content: space-around;
	background: rgba(193, 193, 193, 0.6);
	align-items: stretch;
	overflow: hidden;
	min-height: 36px;
}

#cookiescript_categories {
	display: flex;
	justify-content: center;
	padding: 7px 0;
	border-bottom: 1px solid #f1f1f1;
	flex-wrap: wrap;
}

#cookiescript_cookietablewrap {
	max-width: 1140px;
	overflow: hidden;
	min-height: 216px;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	max-height: 350px;
	box-sizing: border-box;
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
}

#cookiescript_cookietablewrap.cookiescript_hidden {
	opacity: 0.0;
	height: 0;
	min-height: 0;
	max-height: 0;
	margin: 0;
}

#cookiescript_reportwrap {
	height: 137px;
	overflow: auto;
}

#cookiescript_reportwrap > div {
	transition: opacity 200ms 0ms, height 0ms 200ms;
}

.cookiescript_category_description {
	padding: 7px 10px;
	font-size: 11px;
	text-align: left;
	font-weight: normal;
	line-height: 1.5;
	color: #6f6f6f;
	margin: 0;
}

.cookiescript_fullreport th {
	background: #f5f5f5;
	color: #4b4b4b;
}

#cookiescript_categories > div {
	cursor: pointer;
	padding: 0 9px;
	font-size: 11px;
	font-weight: 600;
	background: #f5f5f5;
	color: #4b4b4b;
	line-height: 2;
	margin: 3px 5px;
	white-space: nowrap;
}

#cookiescript_maintabs > div {
	cursor: pointer;
	width: 50%;
	transition: all 300ms ease 0s;
	text-transform: uppercase;
	font-size: 12px;
	text-align: center;
	line-height: 1.5;
	font-weight: 500;
	color: #fefefe;
	padding: 9px 0;
	position: relative;
}

#cookiescript_maintabs .cookiescript_active {
	background: #fefefe;
	color: #009;
}

#cookiescript_declarationwrap {
	background: #fefefe;
	transition: opacity 200ms 0ms, height 0ms 200ms;
}

#cookiescript_categories .cookiescript_active {
	background: #009;
	color: #fefefe;
}

#cookiescript_reportwrap::-webkit-scrollbar-track,
#cookiescript_aboutwrap::-webkit-scrollbar-track {
	background-color: #dadada;
}

#cookiescript_reportwrap::-webkit-scrollbar,
#cookiescript_aboutwrap::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

#cookiescript_reportwrap::-webkit-scrollbar-thumb,
#cookiescript_aboutwrap::-webkit-scrollbar-thumb {
	background-color: #009;
}

.cookiescript_fullreport {
	border-collapse: collapse;
	width: 100%;
}

.cookiescript_fullreport td:nth-child(1) {
	font-weight: 600;
}

.cookiescript_fullreport td:nth-child(3), .cookiescript_fullreport th:nth-child(3) {
	text-align: center;
}

.cookiescript_fullreport td, .cookiescript_fullreport th {
	white-space: normal;
	padding: 0 8px;
	font-size: 11px;
	font-weight: 600;
	text-align: left;
	line-height: 3;
	margin: 0;
}

.cookiescript_fullreport td {
	padding: 7px 8px;
	line-height: 1.3;
	vertical-align: top;
	font-weight: 400;
	border-bottom: 1px solid #f1f1f1;
	border-top: 0;
	border-left: 0;
	border-right: 0;
	color: #6f6f6f;
	background: transparent;
}

.cookiescript_fullreport td:last-child, .cookiescript_fullreport th:last-child {
	padding-right: 18px;
}

.cookiescript_fullreport td:nth-child(1), .cookiescript_fullreport th:nth-child(1) {
	padding-left: 18px;
	word-break: normal;
}

#cookiescript_aboutwrap {
	padding: 7px 18px;
	font-size: 12px;
	text-align: left;
	font-weight: normal;
	line-height: 1.5;
	background-color: #fefefe;
	box-sizing: border-box;
	color: #6f6f6f;
	transition: opacity 200ms 0ms;
	overflow: auto;
	flex-grow: 0;
	height: 180px;
}

#cookiescript_aboutwrap.cookiescript_hidden {
	opacity: 0;
	height: 0;
	overflow: hidden;
	padding: 0;
}

#cookiescript_declarationwrap.cookiescript_hidden {
	opacity: 0;
	height: 0;
	overflow: hidden;
}

#cookiescript_tabscontent {
	background: #fefefe;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

#cookiescript_reportwrap .cookiescript_hidden {
	opacity: 0;
	height: 0;
	transition: opacity 200ms 0ms, height 0ms 200ms;
	overflow: hidden;
}

#cookiescript_reportdate {
	font-size: 11px;
	text-align: right;
	opacity: 0.9;
	padding: 0 10px;
	margin: 0;
}

#cookiescript_injected.hascookiereport #cookiescript_copyright {
	display: none;
}


@media only screen and (max-width: 414px) {
	.cookiescript_fullreport thead {
		display: none;
	}
	
	.cookiescript_fullreport td {
		display: flex;
	}
	
	.cookiescript_fullreport td::before {
		content: attr(label);
		font-weight: bold;
		width: 120px;
		min-width: 120px;
	}
	
	.cookiescript_category_description,
	.cookiescript_fullreport td,
	.cookiescript_fullreport td:nth-child(1),
	.cookiescript_fullreport td:nth-child(3) {
		padding: 7px 10px;
		text-align: left;
	}
	
	.cookiescript_fullreport td:last-child {
		border-bottom: none;
	}
	
	.cookiescript_fullreport tr:nth-child(even) {
		background: #f5f5f5;
	}
	
	.cookiescript_fullreport tr:nth-child(even) td {
		border-bottom: 1px solid #fff;
	}
}


@media screen and (min-width: 0\0
) and (min-resolution: .001dpcm) {
	#cookiescript_maintabs > div {
		display: block;
		float: left;
	}
	
	#cookiescript_categories > div {
		display: inline-block;
	}
	
}


#cookiescript_injected .mdc-checkbox {
	box-sizing: content-box !important;
}

#cookiescript_injected .mdc-checkbox__native-control {
	z-index: 1;
}

#cookiescript_injected .mdc-checkbox .mdc-checkbox__native-control:focus ~ .mdc-checkbox__background::before, #cookiescript_injected .mdc-checkbox .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background::before {
	background-color: var(--mdc-theme-secondary, #fff);
}

.cookiescript_rtl {
	direction: rtl;
}

#cookiescript_injected .mdc-checkbox {display: inline-block;position: relative;flex: 0 0 18px;box-sizing: content-box;width: 18px;height: 18px;line-height: 0;white-space: nowrap;cursor: pointer;vertical-align: bottom;padding: 11px}

#cookiescript_injected .mdc-checkbox .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background::before, #cookiescript_injected .mdc-checkbox .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background::before {background-color: #181813}

@supports not (-ms-ime-align:auto) {
	.mdc-checkbox .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background::before, #cookiescript_injected .mdc-checkbox .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background::before {background-color: var(--mdc-theme-secondary, #181813)}
}

#cookiescript_injected .mdc-checkbox .mdc-checkbox__background {top: 11px;left: 11px}

#cookiescript_injected .mdc-checkbox .mdc-checkbox__background::before {top: -13px;left: -13px;width: 40px;height: 40px}

#cookiescript_injected .mdc-checkbox .mdc-checkbox__native-control {top: 0;right: 0;left: 0;width: 40px;height: 40px}

#cookiescript_injected .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {border-color: #181813;background-color: transparent}

#cookiescript_injected .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background, #cookiescript_injected .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {border-color: #181813;background-color: #181813}

#cookiescript_injected .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {border-color: rgba(24, 24, 19, 0.26);background-color: transparent}

#cookiescript_injected .mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background, #cookiescript_injected .mdc-checkbox__native-control[disabled]:indeterminate ~ .mdc-checkbox__background {border-color: transparent;background-color: rgba(24, 24, 19, 0.26)}

#cookiescript_injected .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {color: #e6e6e6}

#cookiescript_injected .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {color: #e6e6e6}

#cookiescript_injected .mdc-checkbox__background {display: inline-flex;position: absolute;align-items: center;justify-content: center;box-sizing: border-box;width: 18px;height: 18px;border: 2px solid #181813;border-radius: 2px;background-color: transparent;pointer-events: none;will-change: background-color, border-color;transition: background-color 90ms 0s cubic-bezier(.4, 0, .6, 1), border-color 90ms 0s cubic-bezier(.4, 0, .6, 1)}

#cookiescript_injected .mdc-checkbox__background .mdc-checkbox__background::before {background-color: #000}

@supports not (-ms-ime-align:auto) {
	.mdc-checkbox__background .mdc-checkbox__background::before {background-color: var(--mdc-theme-on-surface, #000)}
}

#cookiescript_injected .mdc-checkbox__checkmark {position: absolute;top: 0;right: 0;bottom: 0;left: 0;width: 100%;opacity: 0;transition: opacity 180ms 0s cubic-bezier(.4, 0, .6, 1)}

#cookiescript_injected .mdc-checkbox__checkmark-path {transition: stroke-dashoffset 180ms 0s cubic-bezier(.4, 0, .6, 1);stroke: currentColor;stroke-width: 3.12px;stroke-dashoffset: 29.7833385;stroke-dasharray: 29.7833385}

#cookiescript_injected .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background, #cookiescript_injected .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background {transition: border-color 90ms 0s cubic-bezier(0, 0, .2, 1), background-color 90ms 0s cubic-bezier(0, 0, .2, 1)}

#cookiescript_injected .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path, #cookiescript_injected .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path {stroke-dashoffset: 0}

#cookiescript_injected .mdc-checkbox__background::before {position: absolute;-webkit-transform: scale(0, 0);transform: scale(0, 0);border-radius: 50%;opacity: 0;pointer-events: none;content: "";will-change: opacity, transform;transition: opacity 90ms 0s cubic-bezier(.4, 0, .6, 1), -webkit-transform 90ms 0s cubic-bezier(.4, 0, .6, 1);transition: opacity 90ms 0s cubic-bezier(.4, 0, .6, 1), transform 90ms 0s cubic-bezier(.4, 0, .6, 1);transition: opacity 90ms 0s cubic-bezier(.4, 0, .6, 1), transform 90ms 0s cubic-bezier(.4, 0, .6, 1), -webkit-transform 90ms 0s cubic-bezier(.4, 0, .6, 1)}

#cookiescript_injected .mdc-checkbox__native-control:focus ~ .mdc-checkbox__background::before {-webkit-transform: scale(1);transform: scale(1);opacity: .12;transition: opacity 80ms 0s cubic-bezier(0, 0, .2, 1), -webkit-transform 80ms 0s cubic-bezier(0, 0, .2, 1);transition: opacity 80ms 0s cubic-bezier(0, 0, .2, 1), transform 80ms 0s cubic-bezier(0, 0, .2, 1);transition: opacity 80ms 0s cubic-bezier(0, 0, .2, 1), transform 80ms 0s cubic-bezier(0, 0, .2, 1), -webkit-transform 80ms 0s cubic-bezier(0, 0, .2, 1)}

#cookiescript_injected .mdc-checkbox__native-control {position: absolute;margin: 0;padding: 0;opacity: 0;cursor: inherit}

#cookiescript_injected .mdc-checkbox__native-control:disabled {cursor: default;pointer-events: none}

#cookiescript_injected .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark {transition: opacity 180ms 0s cubic-bezier(0, 0, .2, 1), -webkit-transform 180ms 0s cubic-bezier(0, 0, .2, 1);transition: opacity 180ms 0s cubic-bezier(0, 0, .2, 1), transform 180ms 0s cubic-bezier(0, 0, .2, 1);transition: opacity 180ms 0s cubic-bezier(0, 0, .2, 1), transform 180ms 0s cubic-bezier(0, 0, .2, 1), -webkit-transform 180ms 0s cubic-bezier(0, 0, .2, 1);opacity: 1}

#cookiescript_injected .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark {-webkit-transform: rotate(45deg);transform: rotate(45deg);opacity: 0;transition: opacity 90ms 0s cubic-bezier(.4, 0, .6, 1), -webkit-transform 90ms 0s cubic-bezier(.4, 0, .6, 1);transition: opacity 90ms 0s cubic-bezier(.4, 0, .6, 1), transform 90ms 0s cubic-bezier(.4, 0, .6, 1);transition: opacity 90ms 0s cubic-bezier(.4, 0, .6, 1), transform 90ms 0s cubic-bezier(.4, 0, .6, 1), -webkit-transform 90ms 0s cubic-bezier(.4, 0, .6, 1)}

#cookiescript_injected .mdc-checkbox {-webkit-tap-highlight-color: transparent}

#cookiescript_injected {
	top: 0;
	left: 0;
	right: 0;
	position: fixed;
	padding: 15px 50px 15px 50px;
	width: 100%;
	text-align: left;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin: 0;
	max-height: 85%;
	overflow-y: auto;
	align-items: center;
}

#cookiescript_toppart, #cookiescript_bottompart {
	width: 100%;
}

#cookiescript_toppart {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 0;
}

#cookiescript_rightpart {
	display: flex;
	flex-direction: row;
	align-items: center;
	min-width: 25%;
}

#cookiescript_copyright {
	text-align: left;
	line-height: 1;
	margin: 4px 0 0 0;
}

#cookiescript_buttons {
	flex-wrap: nowrap;
	justify-content: center;
	margin: 0;
	font-weight: 700;
	width: 100%;
	flex-direction: column;
}

#cookiescript_manage_wrap {
	margin: 3px 0 10px 0;
	text-align: left;
}

#cookiescript_header {
	margin: 0 0 10px 0;
	text-align: left;
	padding: 14px 0 0 0;
}

#cookiescript_checkboxs {
	margin: 0 0 0 -11px;
	display: flex;
	flex-wrap: wrap;
}

.cookiescript_checkbox_label {
	padding: 0;
	margin: 0 10px 0 -2px;
}

.cookiescript_checkbox {
	margin: 0;
}

#cookiescript_accept, #cookiescript_reject, #cookiescript_save {
	margin: 5px 7px;
	flex-shrink: 1;
	padding: 0 17px;
}

#cookiescript_description {
	text-align: left;
	font-size: 12px;
	margin: 0;
}

#cookiescript_readmore {
	font-size: 12px;
}

#cookiescript_leftpart {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	max-width: 65%;
}

#cookiescript_injected.hascookiereport #cookiescript_rightpart {
	align-items: baseline;
}

#cookiescript_leftpart {
	-ms-flex: 0 1 741px;
}

#cookiescript_rightpart {
	-ms-flex: 0 1 285px;
}

#cookiescript_buttons {
	-ms-flex: 0 1 100%;
}

#cookiescript_cookietablewrap {
	transition: height 300ms 0ms, min-height 300ms 0ms, max-height 300ms 0ms, opacity 200ms 300ms;
}

#cookiescript_cookietablewrap.cookiescript_hidden {
	transition: height 300ms 0ms, min-height 300ms 0ms, max-height 300ms 0ms, opacity 200ms 0ms;
}

@media only screen and (max-width: 1024px) {
	#cookiescript_buttons {
		flex-wrap: wrap;
	}
	
	#cookiescript_accept, #cookiescript_reject, #cookiescript_save {
		max-width: 100%;
	}
}

@media only screen and (max-width: 700px) {
	#cookiescript_injected {
		padding: 15px 20px;
	}
	
	#cookiescript_toppart {
		flex-direction: column;
	}
	
	#cookiescript_leftpart, #cookiescript_rightpart {
		width: 100%;
		max-width: 100%;
		margin: 0;
		-ms-flex: none;
	}
	
	#cookiescript_buttons {
		flex-wrap: wrap;
		width: 100%;
		flex-direction: row;
	}
	
	#cookiescript_accept, #cookiescript_reject, #cookiescript_save {
		flex-grow: 1;
	}
	
	.hascookiereport #cookiescript_toppart {
		margin-bottom: 10px;
	}
	
}

@media only screen and (max-width: 414px) {
	#cookiescript_injected {
		flex-direction: column;
		width: 100%;
		padding: 15px;
	}
	
	#cookiescript_rightpart {
		flex-direction: column;
	}
	
	#cookiescript_buttons {
		flex-wrap: wrap;
	}
}

/*IE 9 fixes*/
@media screen and (min-width: 0\0
) and (min-resolution: .001dpcm) {
	.cookiescript_checkbox {
		display: inline-block;
	}
	
	#cookiescript_buttons {
		margin-top: 5%;
	}
	
	#cookiescript_leftpart {
		float: left;
		width: 60%;
	}
	
	#cookiescript_rightpart {
		float: right;
		width: 38%;
		margin: 0;
	}
	
	#cookiescript_injected {
		padding: 15px 20px;
	}
	
	#cookiescript_accept, #cookiescript_reject, #cookiescript_save {
		white-space: normal;
	}
	
	#cookiescript_injected {
		max-height: 100%;
	}
}

#cookiescript_badge {
	top: auto; bottom: 0;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	box-shadow: 2px -1px 8px 0 rgba(0, 0, 0, 0.2);
}

#cookiescript_badge:hover {
	padding-top: 8px;
	padding-bottom: 13px;
}

#cookiescript_injected {
	font-family: inherit;
}

#cookiescript_injected,
#cookiescript_readmore,
#cookiescript_description {
	font-size: inherit;
}

#cookiescript_leftpart {
	-ms-flex: 0 1 auto;
}

@media print {
	#cookiescript_badge {
		visibility: hidden;
	}
}
