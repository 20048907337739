.fadein {
	animation: fadein 2s;
	-moz-animation: fadein 2s; /* Firefox */
	-webkit-animation: fadein 2s; /* Safari and Chrome */
	-o-animation: fadein 2s; /* Opera */
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@-moz-keyframes fadein { /* Firefox */
	from {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@-webkit-keyframes fadein { /* Safari and Chrome */
	from {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@-o-keyframes fadein { /* Opera */
	from {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
